@import url(https://fonts.googleapis.com/css?family=Lato:700);
.box {
    position: relative;
    /* max-width: 600px;
    width: 90%;
    height: 400px;
    background: #fff; */
    /* box-shadow: 0 0 15px rgba(0, 0, 0, .1); */
}

/* common */
.ribbon {
    width: 8rem;
    height: 8rem;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 225px;
    border: 1px solid #2980b9;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 15px 0 15px 15px;
    background-color: #3498db;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 500 1em/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    /* font-size: 0.7em; */
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -25px;
    top: 30px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
@font-face {
  font-family: nato;
  src: url(../../static/media/NotoSansHebrew-VariableFont_wdth,wght.46a4db83.ttf);
}

* {
  font-family: nato;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    background: #f0f0f0;
      margin: 12px;
}
