@font-face {
  font-family: nato;
  src: url('./assets/Noto_Sans_Hebrew/NotoSansHebrew-VariableFont_wdth\,wght.ttf');
}

* {
  font-family: nato;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    background: #f0f0f0;
      margin: 12px;
}